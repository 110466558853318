import React from 'react';


export const ImageGrid = () => {
    return (
        <div className='container-fluid d-flex justify-content-center' >
            <div className="image-grid image-grid2 col-11 marginUtility" >
                <img
                    src='images/marinaImg1.jpg'
                    alt=''
                    style={{width:"100%"}}

                />
                <img
                    src='images/gridimg.png'
                    alt='Sample'
                    style={{width:"100%"}}
                />
                <img
                    src='images/gridImgNew.jpg'
                    alt=''
                    style={{width:"100%"}}

                />
                <img
                    src='images/Rectangle5.png'
                    alt='Sample'
                    style={{width:"100%"}}
                />
                <img
                    src='images/fish.png'
                    alt='Sample'
                    className='imgbg5'
                    style={{width:"100%"}}
                />
                <img
                    src='images/gridImgCounter.jpg'
                    alt='Sample'
                    className='imgbg5'
                    style={{width:"100%"}}
                />
                <img
                    src='images/bevrages1.jpg'
                    alt='Sample'
                    className='imgbg5'
                    style={{width:"100%"}}
                />
                <img
                    src='images/bevrages2.jpg'
                    alt='Sample'
                    className='imgbg5'
                    style={{width:"100%"}}
                />
                <img
                    src='images/bevrages3.jpg'
                    alt='Sample'
                    className='imgbg5'
                    style={{width:"100%"}}
                />
            </div>
        </div>

    );
};

export default ImageGrid;
